import React, { useEffect, useState } from "react";
import { Card, Spin, Image, Space, Button } from "antd";

import host from "../../host";
import Link from "antd/es/typography/Link";


export default function OfferCard({ offer }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    fetch(`${host}/api/getOfferImage?url=${encodeURIComponent(offer.data.link)}`)
      .then((res) => res.text()) // Assuming the API returns the image URL as plain text
      .then((url) => {
        setImageUrl(url); // Set the image URL in state
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  }, [offer.data.link]); // Dependency array to re-run the effect if offer.link changes

  return (
    <Card
      style={{ width: 48 + "%" }}
      className="instance"
      cover={
        imageUrl ? (
          <Image
            height={230}
            style={{ objectFit: "cover" }}
            alt={offer.data.title}
            src={imageUrl}
          />
        ) : (
          <div
            style={{
              height: 230,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )
      }
    >
      <Space style={{display: 'flex', flexDirection: 'column', gap: 8}}>
        <b><Link href={offer.data.link} target="_blank" style={{textAlign: 'center'}}>{offer.data.title}</Link></b>
        {offer.data.location}
        {offer.data.price + ' zł'}
        <Button block size="large" type="default" href={offer.data.link} target="_blank">Otwórz ofertę</Button>
        <small>MON ID:{offer.monitoringItem}</small>
      </Space>
    </Card>
  );
}
