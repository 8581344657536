import { useQuery } from '@tanstack/react-query';
import OfferService from '../services/offer.service';

export const useGetOffers = (options = {}) => {
  return useQuery({
    queryKey: ['offers'],  // Klucz zapytania, który identyfikuje to zapytanie w cache
    queryFn: () => OfferService.getOffers(),  // Funkcja, która pobiera dane
    onError: (error) => {
      console.error('Error fetching offers:', error);
    },
    refetchInterval: options.refetchInterval || false, // Automatyczne odświeżanie
    refetchIntervalInBackground: options.refetchIntervalInBackground || false, // Odświeżanie w tle
    staleTime: options.staleTime || 0, // Określa, kiedy dane są traktowane jako przeterminowane
    ...options, // Przekazanie innych opcji do hooka
  });
};