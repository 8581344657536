import { useEffect, useState } from "react";
import { Row, Spin, message, Empty } from "antd";
import OfferCard from "./cards/OfferCard";
import { useGetOffers } from "../hooks/useOffers";

export default function OffersTab() {
  const [visibleCount, setVisibleCount] = useState(10); // Początkowa liczba ofert
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const {
    data: offers,
    isLoading,
    error,
    isFetching,
  } = useGetOffers({
    refetchInterval: 3000,
    refetchIntervalInBackground: false,
    // staleTime: 0,
  });

  useEffect(() => {
    if (isFetching) {
      messageApi.loading("Trwa pobieranie ofert...");
    } else {
      messageApi.destroy();
    }
  }, [isFetching, messageApi]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        !isFetchingMore &&
        visibleCount < (offers?.length || 0)
      ) {
        setIsFetchingMore(true);
        setVisibleCount((prevCount) => prevCount + 10);
        setIsFetchingMore(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingMore, visibleCount, offers?.length]);

  if (error) {
    return <div>Error loading offers.</div>;
  }

  return (
    <div>
      {contextHolder}
      {!isLoading ? (
        <div>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 15,
            }}
          >
            {offers?.slice(0, visibleCount).map((offer, i) => (
              <OfferCard key={offer.id} offer={offer} />
            ))}
          </Row>

          {isFetchingMore && (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Spin />
            </div>
          )}
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
}
