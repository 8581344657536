import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Content from './components/Content';
import { useEffect } from 'react';
import Login from './components/Login';
import { ConfigProvider, theme, FloatButton } from 'antd';
import { MoonOutlined } from '@ant-design/icons';
import AuthService from './services/auth.service';

const App = () => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setDarkMode] = useState(false);

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const handleAuth = (status) => setLoggedIn(status);
  const handleDarkMode = () => {
    setDarkMode((previousValue) => !previousValue);
    localStorage.setItem('darkMode', !isDarkMode);
  };


  const authorizationCheck = async () => {
    setLoading(true);
    try {
      const res = await AuthService.checkLogin();
      setLoggedIn(res);
    } catch (error) {
      console.error("Error during login check:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    authorizationCheck();
    let darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'true') {
      setDarkMode(true);
    }
  }, []);


  const queryClient = new QueryClient();

  return (<QueryClientProvider client={queryClient}>
    <ConfigProvider theme={{
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    }}>
      <FloatButton onClick={handleDarkMode} shape="square" style={{ right: 40 }} icon={<MoonOutlined />} tooltip="Zmiana motywu" />
      {loggedIn && !loading ? <Content onLogout={handleAuth} isDarkMode={isDarkMode} />
        : !loading && <Login onLogin={handleAuth} isDarkMode={isDarkMode} />}
    </ConfigProvider>
  </QueryClientProvider>
  );
};

export default App;
