import React, { useEffect, useState } from "react";
import {
  useGetAllMonitoringItems,
  useDeleteMonitoringItem,
} from "../hooks/useMonitoring";

import CardOLX from "./cards/CardOLX";
import CardOTOMOTO from "./cards/CardOTOMOTO";
import { PlusCircleOutlined } from "@ant-design/icons";

import { Row, Dropdown, message } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ManageFloatingButtons from "./ManageFloatingButtons";

export default function ManageTab({ onLogout, isDarkMode }) {
  const {
    data: monitoringItems,
    isLoading,
    error,
  } = useGetAllMonitoringItems();
  const deleteMonitoringItem = useDeleteMonitoringItem();

  const [localMonitoringItems, setLocalMonitoringItems] = useState(
    monitoringItems || []
  );

  const addCard = (portal) => {
    const newCard = {
      portal,
      _id: `local-${Date.now()}`, // Tworzenie unikalnego id dla lokalnej karty
      url: "",
      filterKeywords: [],
      excludeCategories: [],
      excludeKeywords: [],
      excludeRefreshed: false,
      excludeExternal: false,
      name: "",
      isActive: false,
    };

    setLocalMonitoringItems([...localMonitoringItems, newCard]);
  };

  const applySavedOrder = (items) => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (!savedOrder.length) return items;

    const orderedItems = items.slice().sort((a, b) => {
      return savedOrder.indexOf(a._id) - savedOrder.indexOf(b._id);
    });

    return orderedItems;
  };

  useEffect(() => {
    if (monitoringItems) {
      const orderedItems = applySavedOrder(monitoringItems);
      setLocalMonitoringItems([...orderedItems]);
    }
  }, [monitoringItems]);

  const deleteItem = async (id) => {
    try {
      if (id.includes("local")) {
        setLocalMonitoringItems(
          localMonitoringItems.filter((item) => item._id !== id)
        );
        return;
      }
      await deleteMonitoringItem.mutateAsync(id);
    } catch (error) {
      console.log("Error during deleteItem:", error);
    }
  };

  const saveOrderToLocalStorage = (items) => {
    let order = items.map((item) => item._id);
    order = order.filter((o) => o !== null);
    localStorage.setItem("order", JSON.stringify(order));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(localMonitoringItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocalMonitoringItems(items);
    saveOrderToLocalStorage(items);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="instances">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {localMonitoringItems.map((item, index) => (
                <Draggable
                  key={item._id || item.id || index}
                  draggableId={item._id || item.id || index.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item.url.includes("olx.pl/") || item.portal === "olx" ? (
                        <CardOLX
                          key={item._id}
                          url={item.url}
                          id={item._id}
                          filterKeywords={item.filterKeywords}
                          excludeKeywords={item.excludeKeywords}
                          excludeCategories={item.excludeCategories}
                          excludeRefreshed={item.excludeRefreshed}
                          excludeExternal={item.excludeExternal}
                          isActive={item.isActive}
                          name={item.name}
                          deleteInstance={deleteItem}
                          //   update={startAll} // <- or stopAll, update state using the respective function
                          isDarkMode={isDarkMode}
                        />
                      ) : item.url.includes("otomoto.pl/") ||
                        item.portal === "otomoto" ? (
                        <CardOTOMOTO
                          key={item._id}
                          url={item.url}
                          id={item._id}
                          filterKeywords={item.filterKeywords}
                          excludeKeywords={item.excludeKeywords}
                          excludeCategories={item.excludeCategories}
                          excludeRefreshed={item.excludeRefreshed}
                          excludeExternal={item.excludeExternal}
                          isActive={item.isActive}
                          name={item.name}
                          deleteInstance={deleteItem}
                          //   update={startAll} // <- or stopAll, update state using the respective function
                          isDarkMode={isDarkMode}
                        />
                      ) : (
                        <div>Unknown url</div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Row
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
        }}
        gutter={[16, 16]}
      >
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: "OLX",
                style: { textAlign: "center" },
                onClick: () => addCard("olx"),
              },
              {
                key: "2",
                label: "OTOMOTO",
                style: { textAlign: "center" },
                onClick: () => addCard("otomoto"),
              },
            ],
          }}
          trigger={["click"]}
          arrow={{
            pointAtCenter: true,
          }}
          placement="bottom"
        >
          <PlusCircleOutlined className="plus" style={{ fontSize: 30 }} />
        </Dropdown>
      </Row>
      <ManageFloatingButtons localMonitoringItems={localMonitoringItems} setLocalMonitoringItems={setLocalMonitoringItems} />
    </div>
  );
}
