import { useState } from "react";
import { Layout, Col, FloatButton, Row, Typography, Segmented, Badge } from "antd";
import { ControlOutlined, BarsOutlined } from "@ant-design/icons";
import ManageTab from "./ManageTab";
import OffersTab from "./OffersTab";
const { Content } = Layout;
const { Text } = Typography;
export default function ContentComponent({ onLogout, isDarkMode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState("edit");
  const [offersFetching, setOffersFetching] = useState(false);


  return (
    <div
      style={{
        background: isDarkMode ? "#000" : "#F4F5F6",
        minHeight: 100 + "dvh",
        maxHeight: "max-content",
      }}
    >
      <Content>
        {!isLoading && (
          <div className="site-layout-content">
            <Row style={{ marginBottom: 50 }}>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 30, fontWeight: 600 }}>
                  Monitorowanie ogłoszeń
                </Text>
                <Text
                  type="secondary"
                  style={{ fontSize: 16, fontWeight: 400 }}
                >
                  Zarządzaj monitorowaniem OLX i OTOMOTO
                </Text>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Segmented
                size="large"
                style={{ marginBottom: 20 }}
                value={tab}
                onChange={(value) => setTab(value)}
                options={[
                  {
                    label: "Zarządzanie",
                    value: "edit",
                    icon: <ControlOutlined />,
                  },
                  {
                    label: "Ogłoszenia",
                    value: "offers",
                    icon: <BarsOutlined />,
                  },
                ]}
              />
            </Row>
            {tab === "offers" ? (
              <OffersTab />
            ) : (
              <ManageTab isDarkMode={isDarkMode} />
            )}
          </div>
        )}
        <FloatButton.BackTop
          style={{ right: 40, bottom: 110 }}
          shape="square"
          visibilityHeight={0}
          tooltip="Przewiń do góry"
        />
      </Content>
    </div>
  );
}
