import React from "react";
import AuthService from "../services/auth.service";
import { useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Layout, Card, Form, Input, message, Button } from "antd";


const { Content } = Layout;

export default function Login({ onLogin, isDarkMode }) {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleAuthorization = async (values) => {
    setLoading(true);
    try {
      const res = await AuthService.login(values);
      if (res.status === 200) {
        localStorage.setItem("auth", res.data.token);
        onLogin(true);
        messageApi.open({
          type: "success",
          content: "Zalogowano pomyślnie",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      messageApi.open({
        type: "error",
        content:
          error.response?.data?.message || "Wystąpił błąd podczas logowania",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content
      style={{ padding: "0 50px", background: !isDarkMode ? "#F5F5F7" : '#141414', height: 100 + "vh" }}
    >
      {contextHolder}
      <div className="site-layout-content">
        <Card title="Logowanie" bordered={false} style={{ width: 300, backgroundColor: isDarkMode && "#1F1F1F" }}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleAuthorization}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Login jest wymagany" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Login"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Hasło jest wymagane" }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Hasło"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                className="login-form-button"
              >
                Zaloguj się
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Content>
  );
}
