import React from "react";
import { useEffect } from "react";
import axios from "axios";
import host from "../../host";
import { isMobile } from "react-device-detect";
import MonitoringService from "../../services/monitoring.service";
import {
  Badge,
  Card,
  Row,
  Col,
  Input,
  Button,
  message,
  Space,
  Popconfirm,
  Modal,
  Flex,
  Tag,
  Typography,
  Tooltip,
  Dropdown,
  Switch,
} from "antd";
import {
  LinkOutlined,
  PlayCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  FilterOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

export default function Instance({
  isActive,
  id,
  url,
  filterKeywords,
  excludeCategories,
  excludeKeywords,
  excludeRefreshed,
  excludeExternal,
  deleteInstance,
  name,
  isDarkMode,
}) {
  const [newUrl, setNewUrl] = React.useState(url);
  const [isRunning, setIsRunning] = React.useState(isActive);
  const [isSettingsOpen, setSettingsOpen] = React.useState(false);
  const [filterKeywordsState, setFilterKeywords] = React.useState(
    filterKeywords || []
  );
  const [excludeKeywordsState, setExcludeKeywords] = React.useState(
    excludeKeywords || []
  );
  const [excludeCategoriesState, setExcludeCategories] = React.useState(
    excludeCategories || []
  );
  const [excludeRefreshedState, setExcludeRefreshed] = React.useState(
    excludeRefreshed || false
  );
  const [excludeExternalState, setExcludeExternal] = React.useState(
    excludeExternal || false
  );
  const [nameState, setName] = React.useState(name || "");
  const [messageApi, contextHolder] = message.useMessage();
  const [excludedCategoriesText, setExcludeCategoriesText] = React.useState(
    excludeCategoriesState.join("\n")
  );
  const [excludedKeywordsText, setExcludeKeywordsText] = React.useState(
    excludeKeywordsState.join("\n")
  );
  const [filterKeywordsText, setFilterKeywordsText] = React.useState(
    filterKeywordsState.join("\n")
  );

  useEffect(() => {
    setExcludeCategoriesText(excludeCategoriesState.join("\n"));
    setExcludeKeywordsText(excludeKeywordsState.join("\n"));
  }, [excludeCategoriesState, excludeKeywordsState]);

  function getShortenedString(text) {
    // Check if the string is less than 9 characters
    if (text.length < 9) {
      return text; // No shortening needed for short strings
    }

    // Extract the first 4 digits
    const firstPart = text.substring(0, 4);

    // Extract the last 4 digits
    const lastPart = text.substring(text.length - 4);

    // Combine with "..."
    return `${firstPart}…${lastPart}`;
  }

  const start = async () => {
    if (!newUrl || newUrl === "" || newUrl === " ") {
      messageApi.open({
        type: "error",
        content: "Podaj link do monitorowania",
      });
      return;
    } else if (!newUrl.includes("otomoto.pl")) {
      messageApi.open({
        type: "error",
        content: "Podaj link z OTOMOTO",
      });
      return;
    }

    try {
      const data = await MonitoringService.start({
        url: newUrl,
        _id: id.includes('local') ? null : id,
        filterKeywords: filterKeywordsState || [],
        excludeKeywords: excludeKeywordsState || [],
        excludeCategories: excludeCategoriesState || [],
        excludeRefreshed: excludeRefreshedState,
        excludeExternal: excludeExternalState,
        name: nameState,
      });
      messageApi.open({
        type: "success",
        content: "Monitoring rozpoczęty",
      });
      setIsRunning(true);
    } catch (error) {
      console.error(
        "Error starting monitoring:",
        error.response?.data || error
      );
      messageApi.open({
        type: "error",
        content: error.response?.data || error.message,
      });
    }
  };

  const stop = async () => {
    try {
      await MonitoringService.stop(id);
      messageApi.open({
        type: "success",
        content: "Monitoring zatrzymany",
      });
      setIsRunning(false);
    } catch (error) {
      console.log("Error stopping monitoring:", error);
      messageApi.open({
        type: "error",
        content: error.response?.data || error.message,
      });
    }
  };

  const setURL = (e) => {
    setNewUrl(e.target.value);
  };

  const remove = async () => {
    deleteInstance(id);
  };

  const saveFilters = () => {
    setFilterKeywords(
      filterKeywordsText.split("\n").filter((key) => key !== "")
    );
    setExcludeCategories(
      excludedCategoriesText.split("\n").filter((cat) => cat !== "")
    );
    setExcludeKeywords(
      excludedKeywordsText.split("\n").filter((key) => key !== "")
    );
    setSettingsOpen(false);
  };

  const mobileMenu = [
    {
      key: 1,
      label: "Filtry",
      icon: <FilterOutlined />,
      onClick: () => setSettingsOpen(true),
    },
    {
      key: 2,
      label: "Usuń",
      icon: <DeleteOutlined />,
      onClick: remove,
    },
  ];

  return (
    <div className="instance">
      {contextHolder}
      <Card
        style={{
          backgroundColor: isDarkMode ? "#1F1F1F" : "white",
        }}
        title={
          <Flex align="center" gap="4px">
            <Badge
              count={isRunning ? "Aktywny" : "Nieaktywny"}
              color={isRunning ? "#52c41a" : "volcano"}
            />
            <Typography.Title
              style={{ margin: 0, paddingLeft: 12 }}
              level={5}
              disabled={isRunning}
              editable={
                !isRunning
                  ? {
                      onChange: setName,
                      maxLength: 64,
                      text: nameState,
                    }
                  : false
              }
            >
              {nameState}
            </Typography.Title>
          </Flex>
        }
        extra={
          !isMobile ? (
            <Space>
              {id && (
                <Tooltip title={id}>
                  <Text copyable={{ text: id }} type="secondary">
                    {!isMobile ? getShortenedString(id) : "ID"}
                  </Text>
                </Tooltip>
              )}

              <Button
                size="small"
                icon={<FilterOutlined />}
                onClick={() => setSettingsOpen(true)}
                type="text"
                disabled={isRunning}
              >
                Filtry
              </Button>

              <Popconfirm
                placement="topRight"
                title={"Usunąć pozycję?"}
                description={
                  "Napewno chcesz usunąć tą pozycję i skasować historie monitorowania dla tego linku?"
                }
                onConfirm={remove}
                okText="Tak"
                cancelText="Nie"
              >
                <Button
                  danger
                  size="small"
                  type="text"
                  icon={<DeleteOutlined />}
                >
                  Usuń
                </Button>
              </Popconfirm>
            </Space>
          ) : (
            <Dropdown
              menu={{ items: mobileMenu }}
              placement="bottomRight"
              arrow
              disabled={isRunning}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          )
        }
        size="large"
      >
        <Modal
          open={isSettingsOpen}
          title={"Filtrowanie: " + nameState}
          width={400}
          onCancel={() => saveFilters()}
          footer={null}
        >
          {/* <Flex align="top" gap={8} justify="start" style={{ marginBottom: 8 }}>
            <Switch
              size="small"
              style={{ marginTop: 2 }}
              checked={excludeRefreshedState}
              onChange={() => setExcludeRefreshed(!excludeRefreshedState)}
            />
            <Text>Wyklucz odswiezone</Text>
          </Flex>
          <Flex align="top" gap={8} justify="start" style={{ marginBottom: 8 }}>
            <Switch
              size="small"
              style={{ marginTop: 2 }}
              checked={excludeExternalState}
              onChange={() => setExcludeExternal(!excludeExternalState)}
            />
            <Text>
              Wyklucz wychodzące linki{" "}
              <span style={{ fontSize: 12, color: "grey" }}>
                (OTOMOTO, OTODOM)
              </span>
            </Text>
          </Flex> */}
          <Space align="start">
            <TextArea
              allowClear
              placeholder="Filtruj wg. słów kluczowych"
              autoSize={{ minRows: 8, maxRows: 10 }}
              value={filterKeywordsText}
              onChange={(e) => {
                setFilterKeywordsText(e.target.value);
              }}
            />
            <TextArea
              allowClear
              placeholder="Wyklucz słowa kluczowe"
              autoSize={{ minRows: 8, maxRows: 10 }}
              value={excludedKeywordsText}
              onChange={(e) => {
                setExcludeKeywordsText(e.target.value);
              }}
            />
            {/* <TextArea
              allowClear
              placeholder="Wyklucz kategorie"
              autoSize={{ minRows: 8, maxRows: 10 }}
              value={excludedCategoriesText}
              onChange={(e) => {
                setExcludeCategoriesText(e.target.value);
              }}
            /> */}
          </Space>
          <div style={{ marginTop: 10 }}>
            * Po jednej kategorii/słowie kluczowym w jednej linii
          </div>
        </Modal>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={20}>
            <Input
              size="large"
              addonBefore={<LinkOutlined />}
              disabled={isRunning}
              placeholder="Link z OTOMOTO"
              value={newUrl}
              onChange={setURL}
              onKeyDown={(e) => {
                // Start monitoring on Enter
                if (e.key === "Enter") {
                  start();
                }
              }}
            />
          </Col>
          <Col xs={24} sm={4}>
            <Button
              block
              size="large"
              type="primary"
              danger={isRunning}
              onClick={!isRunning ? start : stop}
            >
              {!isRunning ? (
                <>
                  <PlayCircleOutlined /> Start
                </>
              ) : (
                <>
                  <StopOutlined /> Stop
                </>
              )}
            </Button>
          </Col>
        </Row>

        {excludeCategoriesState.length ||
        filterKeywordsState.length ||
        excludeKeywordsState.length ||
        excludeExternalState ||
        excludeRefreshedState ? (
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            <Col>
              <Space direction="vertical">
                {filterKeywordsState.length ? (
                  <>
                    <b>Filtry</b>
                    <Flex gap="4px 0" wrap>
                      <span style={{ marginRight: 4 }}>Słowa kluczowe:</span>
                      {filterKeywordsState.map((keyword) => (
                        <Tag
                          key={keyword}
                          bordered={false}
                          closable={!isRunning}
                          color="gold"
                          onClose={() => {
                            setFilterKeywords(
                              filterKeywordsState.filter((c) => c !== keyword)
                            );
                            setFilterKeywordsText(
                              filterKeywordsState
                                .filter((c) => c !== keyword)
                                .join("\n")
                            );
                          }}
                        >
                          {keyword}
                        </Tag>
                      ))}
                    </Flex>
                  </>
                ) : null}
                {excludeCategoriesState.length ||
                excludeKeywordsState.length ||
                excludeExternalState ||
                excludeRefreshedState ? (
                  <b>Wykluczenia</b>
                ) : null}
                {excludeRefreshedState ? (
                  <Flex align="top" gap={8} justify="start">
                    <Switch
                      size="small"
                      style={{ marginTop: 2 }}
                      disabled={isRunning}
                      checked={excludeRefreshedState}
                      onChange={() => {
                        setExcludeRefreshed(!excludeRefreshedState);
                      }}
                    />
                    <Text>Wyklucz odswiezone</Text>
                  </Flex>
                ) : null}
                {excludeExternalState ? (
                  <Flex align="top" gap={8} justify="start">
                    <Switch
                      size="small"
                      style={{ marginTop: 2 }}
                      disabled={isRunning}
                      checked={excludeExternalState}
                      onChange={() => {
                        setExcludeExternal(!excludeExternalState);
                      }}
                    />
                    <Text>Wyklucz wychodzące linki</Text>
                  </Flex>
                ) : null}
                {excludeCategoriesState.length ? (
                  <Flex gap="4px 0" wrap>
                    <span style={{ marginRight: 4 }}>Kategorie:</span>
                    {excludeCategoriesState.map((cat) => (
                      <Tag
                        key={cat}
                        bordered={false}
                        closable={!isRunning}
                        color="purple"
                        onClose={() => {
                          setExcludeCategories(
                            excludeCategoriesState.filter((c) => c !== cat)
                          );
                          setExcludeCategoriesText(
                            excludeCategoriesState
                              .filter((c) => c !== cat)
                              .join("\n")
                          );
                        }}
                      >
                        {cat}
                      </Tag>
                    ))}
                  </Flex>
                ) : null}
                {excludeKeywordsState.length ? (
                  <Flex gap="4px 0" wrap>
                    <span style={{ marginRight: 4 }}>Słowa kluczowe:</span>
                    {excludeKeywordsState.map((keyword) => (
                      <Tag
                        key={keyword}
                        bordered={false}
                        closable={!isRunning}
                        color="gold"
                        onClose={() => {
                          setExcludeKeywords(
                            excludeKeywordsState.filter((c) => c !== keyword)
                          );
                          setExcludeKeywordsText(
                            excludeKeywordsState
                              .filter((c) => c !== keyword)
                              .join("\n")
                          );
                        }}
                      >
                        {keyword}
                      </Tag>
                    ))}
                  </Flex>
                ) : null}
              </Space>
            </Col>
          </Row>
        ) : null}
      </Card>
    </div>
  );
}
