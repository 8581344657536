import host from "../host";
import axios from "axios";

class AuthService {
    login = async (values) => {
        return await axios.post(`${host}/api/login`, values);
    };

    checkLogin = async () => {
        const token = localStorage.getItem('auth');
        try {
            const res = await axios.post(`${host}/api/login`, {}, {
                headers: {
                    'authorization': token
                }
            });
            return res;
        } catch (error) {
            console.error("Error during checkLogin:", error);
            throw error;
        }
    };

    logout = () => {
        localStorage.removeItem('auth');
        window.location.reload();
    };
}

export default new AuthService();