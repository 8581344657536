import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import MonitoringService from '../services/monitoring.service';

// Hook do pobierania wszystkich monitorowanych elementów
export const useGetAllMonitoringItems = () => {
  return useQuery({
    queryKey: ['monitoringItems'],
    queryFn: MonitoringService.getAll,
    onError: (error) => {
      console.error('Error fetching monitoring items:', error);
    },
  });
};

// Hook do usuwania elementu monitoringu
export const useDeleteMonitoringItem = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => MonitoringService.deleteItem(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['monitoringItems'],
      });
    },
    onError: (error) => {
      console.error('Error deleting monitoring item:', error);

    },
  });
};

// Hook do uruchomienia wszystkich monitorowanych elementów
export const useStartAllMonitoringItems = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: MonitoringService.startAll,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['monitoringItems'],
        
      });
    },
    onError: (error) => {
      console.error('Error starting all monitoring items:', error);
    },
  });
};

// Hook do zatrzymania wszystkich monitorowanych elementów
export const useStopAllMonitoringItems = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: MonitoringService.stopAll,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['monitoringItems'],
        
      });
    },
    onError: (error) => {
      console.error('Error stopping all monitoring items:', error);
    },
  });
};

// Hook do usunięcia wszystkich monitorowanych elementów
export const useDeleteAllMonitoringItems = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: MonitoringService.deleteAll,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['monitoringItems'],
      });
    },
    onError: (error) => {
      console.error('Error deleting all monitoring items:', error);
    },
  });
};