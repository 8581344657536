import axios from 'axios';
import host from '../host';

class OfferService {
    async getOffers() {
        try {
            const { data } = await axios.get(`${host}/api/getOffers`, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });
            
            return data;
        } catch (error) {
            console.error("Error fetching offers:", error);
            throw error;
        }
    }

    async getOfferImage(url) {
        try {
            const { data } = await axios.get(`${host}/api/getOfferImage?url=${url}`, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });
            
            return data;
        } catch (error) {
            console.error("Error fetching offer image:", error);
            throw error;
        }
    }
}

export default new OfferService();