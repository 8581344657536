import axios from "axios";
import host from "../host";

class MonitoringService {

    static async getAll() {
        try {
            const res = await axios.get(`${host}/api/monitoring`, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error fetching monitoring data:", error);
            throw error;
        }
    }

    static async deleteItem(id) {
        try {
            const res = await axios.post(
                `${host}/api/delete`,
                { id }, // Dane przekazywane w ciele żądania
                {
                    headers: {
                        Authorization: localStorage.getItem("auth"), // Nagłówek autoryzacyjny
                    }
                }
            );

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error(`Error deleting item with id ${id}:`, error);
            throw error;
        }
    }

    static async stop(id) {
        try {
            const res = await axios.post(`${host}/api/stop`, { id }, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error during stop operation:", error.response?.data || error.message);
            throw error;
        }
    }

    static async start(data) {
        try {
            const res = await axios.post(`${host}/api/start`, data, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error during start operation:", error.response?.data || error.message);
            throw error;
        }
    }

    static async startAll() {
        try {
            const res = await axios.post(`${host}/api/start/all`, {}, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error during start all operation:", error.response?.data || error.message);
            throw error;
        }
    }

    static async stopAll() {
        try {
            const res = await axios.post(`${host}/api/stop/all`, {}, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error during stop all operation:", error.response?.data || error.message);
            throw error;
        }
    }

    static async deleteAll() {
        try {
            const res = await axios.post(`${host}/api/delete/all`, {}, {
                headers: {
                    Authorization: localStorage.getItem("auth"),
                },
            });

            if (res.status !== 200) {
                throw new Error(`Unexpected response status: ${res.status}`);
            }

            return res.data;
        } catch (error) {
            console.error("Error during delete all operation:", error.response?.data || error.message);
            throw error;
        }
    }
}

export default MonitoringService;