import { FloatButton } from "antd";
import {
  PlayCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  useStartAllMonitoringItems,
  useStopAllMonitoringItems,
  useDeleteAllMonitoringItems,
} from "../hooks/useMonitoring";
import AuthService from "../services/auth.service";

export default function ManageFloatingButtons({
  localMonitoringItems,
  setLocalMonitoringItems,
}) {
  const startAllMonitoringItems = useStartAllMonitoringItems();
  const stopAllMonitoringItems = useStopAllMonitoringItems();
  const deleteAllMonitoringItems = useDeleteAllMonitoringItems();

  const deleteAll = async () => {
    try {
      for (const item of localMonitoringItems) {
        if (item._id.includes("local")) {
        } else {
          await deleteAllMonitoringItems.mutateAsync();
        }
      }
    } catch (error) {
      console.error("Error deleting all monitoring items:", error);
    }
  };

  const startAll = async () => {
    try {
      setLocalMonitoringItems([]);
      await startAllMonitoringItems.mutateAsync();
    } catch (error) {
      console.error("Error starting all monitoring items:", error);
    }
  };

  const stopAll = async () => {
    try {
      setLocalMonitoringItems([]);
      await stopAllMonitoringItems.mutateAsync();
    } catch (error) {
      console.error("Error stopping all monitoring items:", error);
    }
  };

  return (
    <FloatButton.Group
      shape="square"
      trigger="click"
      style={{ right: 40, bottom: 170 }}
      icon={<MenuOutlined />}
    >
      <FloatButton
        onClick={startAll}
        icon={<PlayCircleOutlined />}
        tooltip="Wystartuj wszystkie"
      />
      <FloatButton
        onClick={stopAll}
        icon={<StopOutlined />}
        tooltip="Zatrzymaj wszystkie"
      />
      <FloatButton
        onClick={deleteAll}
        icon={<DeleteOutlined />}
        tooltip="Usuń wszystkie"
      />
      <FloatButton
        onClick={AuthService.logout}
        icon={<LogoutOutlined />}
        tooltip="Wyloguj się"
      />
    </FloatButton.Group>
  );
}
